import React from "react"
import { graphql } from "gatsby"
import Picture from "gatsby-image"
import Layout from "../components/layout"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const InfoPage = ({ data }) => {
  return (
    <Layout>
      <h1>General Information</h1>
      <div style={{ float: `left`, maxWidth: `800px` }}>
        <h2>
          The Caledonian Village Owners' Association <em>(TCVOA)</em>
        </h2>
        <hr />
        <p>
          The Caledonian Village Owners Association, or TCVOA for short, was
          formed at an inaugural meeting on December 1st 2010, in accordance
          with the terms set out in the Deed of Conditions for the development.
          When you buy a property you automatically become a member. We have an
          elected committee of currently five members, which includes a
          Chairperson, Secretary and Treasurer. The committee is open to anyone
          who wishes to join, so if you are interested in joining or just
          lending support please do let us know. You can contact us by email at{" "}
          <a href="mailto:tcvoa.edinburgh@gmail.com">
            tcvoa.edinburgh@gmail.com
          </a>
          .
        </p>
        <p>
          TCVOA committee has the authority to carry out certain duties to
          manage the development. Working closely with the property factor, we
          represent owners’ interests and can monitor and authorise expenditures
          where necessary. Although Trinity Factors (
          <a href="mailto:admin@trinityfactors.co.uk">
            admin@trinityfactors.co.uk
          </a>
          ) should be your first contact in most cases, you can contact us by
          email for information or assistance, or if you have knowledge of
          something you would like us to pass on to other owners. From time to
          time we circulate information, usually in the form of a Newsletter to
          inform owners about what is going on in Caledonian Village and
          sometimes we may request your input. We ask that you read our
          communications and take any necessary actions. We hold an AGM, usually
          in March, to which all owners are invited and at which time committee
          members are elected or re-elected. Please note we are not a social
          organisation, this is about the management of your property.
        </p>

        <p>
          Owners should contact us at{" "}
          <a href="mailto:tcvoa.edinburgh@gmail.com">
            tcvoa.edinburgh@gmail.com
          </a>{" "}
          to request inclusion on the mailing list, so that you may be kept
          informed.
        </p>

        <p>
          We would like to remind owners who rent out their property that it is
          their responsibility to ensure that tenants abide by the terms of the
          Deed of Conditions, which forms the basis of much of the information
          which follows.
        </p>

        <h2>Plan of Caledonian Village</h2>
        <hr />
        <p>
          The following map shows the area designated as Calendonian
          Village, sourced from https://scotlis.ros.gov.uk
        </p>

        <div
          style={{
            maxWidth: `700px`,
            display: `block`,
            marginLeft: `auto`,
            marginRight: `auto`,
          }}
        >
          <Picture
            className="calevillage-map.jpg"
            fluid={data.file.childImageSharp.fluid}
            alt="calevillage-map.jpg"
          />
        </div>
        <p>
          © Crown copyright 2024
        </p>

        <h2>Resident Parking</h2>
        <hr />

        <p>
          Resident only parking is available in several locations around
          Caledonian Village. A permit is required to park in these areas and
          they are for cars only. See <a href="/parking/">Parking</a> for more
          information.
        </p>

        <h2>Bin and Bike Stores</h2>
        <hr />
        <p>
          There are many bin and bike stores available in various locations
          around the development, all of which are for communal use. Please see{" "}
          <a href="/bins/">Bins</a> and <a href="/parking/">Parking</a> for more
          information.
        </p>

        <h2>Items left in communal areas</h2>
        <hr />
        <p>
          Be advised that storing items within the communal stairwells, or
          indeed within the electrical cupboards, is a fire and safety hazard.
          Please ensure that buggies, bikes or other items are kept inside your
          own property. Any items left in the stairwells or cupboards are
          subject to removal by the property manager.
        </p>

        <h2>Buildings insurance</h2>
        <hr />
        <p>
          There is a block buildings insurance policy in place which is
          administered by Trinity Factors, (
          <a href="mailto:admin@trinityfactors.co.uk">
            admin@trinityfactors.co.uk
          </a>
          ) who are the first point of contact for any claims.
        </p>

        <h2>Internet</h2>
        <hr />
        <p>
          All properties within Caledonian Village should have direct
          connections to the{" "}
          <OutboundLink href="https://www.virginmedia.com">Virgin Media</OutboundLink> (FTTC) network.
          In 2019 BT Openworld installed their latest fibre to the front door of
          every property (FTTP).
        </p>
        <p>If an engineer requires access to an electrical cupboard, this must be arranged in advance by <a href="/factor/">contacting Trinity Factors</a> who has the access code for the keypads.</p>

        <h2>Satellite dishes</h2>
        <hr />
        <p>
          It is not permitted to install satellite dishes within the
          development, which would in any event probably require planning
          consent. Any dishes so installed are subject to removal.
        </p>

        <h2>For Sale / To Let boards</h2>
        <hr />
        <p>
          These may not be attached to the outside of the buildings or to any
          communal property such as bin or bike stores and owners should
          instruct their agents accordingly. Instead boards can be displayed
          from inside against a window.
        </p>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    file(relativePath: { eq: "images/calevillage-map.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default InfoPage
